async function init(): Promise<void> {
  const temperatureInput = document.querySelector("#inputTemperature") as HTMLInputElement;
  const speedInput = document.querySelector("#inputSpeed") as HTMLInputElement;

  const elements = {
    "100d": document.querySelector("#range100d"),
    p100d: document.querySelector("#rangeP100d"),
    acButton: document.querySelector("#acButton"),
    wheel19Button: document.querySelector("#wheel19Button"),
    wheel21Button: document.querySelector("#wheel21Button"),
  };

  const variables = {
    speed: 100,
    temp: 20,
    wheelSize: 19, // 19 or 21
    ac: "on",
  };

  const data = {
    "100d": await import("../data/100D.json"),
    p100d: await import("../data/p100D.json"),
  };

  update(variables, data, elements);

  temperatureInput.oninput = function (event: Event) {
    variables.temp = parseInt((event.target as HTMLInputElement).value);
    update(variables, data, elements);
  };

  speedInput.addEventListener("input", (event) => {
    variables.speed = parseInt((event.target as HTMLInputElement).value);
    update(variables, data, elements);
  });

  elements["acButton"].addEventListener("click", () => {
    variables.ac = variables.ac === "on" ? "off" : "on";
    update(variables, data, elements);
  });

  elements["wheel19Button"].addEventListener("click", () => {
    variables.wheelSize = 19;
    update(variables, data, elements);
  });

  elements["wheel21Button"].addEventListener("click", () => {
    variables.wheelSize = 21;
    update(variables, data, elements);
  });

  (document.querySelector("#inputTemperatureUp") as HTMLButtonElement).onclick = function () {
    up("#inputTemperature");
  };

  (document.querySelector("#inputTemperatureDown") as HTMLButtonElement).onclick = function () {
    down("#inputTemperature");
  };

  (document.querySelector("#inputSpeedUp") as HTMLButtonElement).onclick = function () {
    up("#inputSpeed");
  };

  (document.querySelector("#inputSpeedDown") as HTMLButtonElement).onclick = function () {
    down("#inputSpeed");
  };
}

function update(variables, data, elements) {
  setRange(elements["100d"], calculateRange(variables, data["100d"]));
  setRange(elements["p100d"], calculateRange(variables, data["p100d"]));
  updateAcButton(variables, elements);
  updateWheels(variables, elements);
}

function calculateRange(variables, data) {
  const { temp, wheelSize, ac, speed } = variables;

  const item = data.find((d) => d.temp === temp && d.wheelsize === wheelSize && d.ac === ac);

  if (item == null) {
    console.error("No data point found for this set of variables");
    return 0;
  }

  const range = item.hwy.find((d) => d.kmh == speed)?.kilometers;

  return range;
}

function setRange(el, range) {
  el.childNodes[0].textContent = range;
}

function updateAcButton(variables, elements) {
  const { temp, ac } = variables;

  const heating = temp < 20;
  const on = ac === "on";
  const button = elements["acButton"];
  const img = button.querySelector("img") as HTMLImageElement;
  const span = button.querySelector("span") as HTMLSpanElement;
  const inner = button.querySelector(".inner") as HTMLDivElement;

  const images = {
    "ac-on": new URL("../images/icon-fan-white.svg", import.meta.url),
    "ac-off": new URL("../images/icon-fan-gray.svg", import.meta.url),
    "heat-on": new URL("../images/icon-wave-white.svg", import.meta.url),
    "heat-off": new URL("../images/icon-wave-gray.svg", import.meta.url),
  };

  img.src = images[`${heating ? "heat" : "ac"}-${ac}`];
  span.innerHTML = `${heating ? "HEAT" : "AC"} ${on ? "ON" : "OFF"}`;

  if (!on) {
    inner.style.backgroundColor = "white";
    span.style.color = "#707070";
    return;
  }

  if (heating) {
    inner.style.backgroundColor = "#CC0000";
    span.style.color = "white";
  } else {
    inner.style.backgroundColor = "#1876c4";
    span.style.color = "white";
  }
}

function calculateWheelSpeed(speed: number) {
  // 0.2 - 0.8
  const delta = 1 - (speed - 70) / 70;
  const timing = delta * 0.6 + 0.2;
  return `${timing}s`;
}

function updateWheels(variables, elements) {
  const { wheelSize } = variables;

  document.querySelectorAll(".wheel").forEach((n) => n.classList.remove("active"));

  elements[`wheel${wheelSize}Button`].classList.add("active");

  const wheelSpeed = calculateWheelSpeed(variables.speed);

  document.querySelectorAll(".car-wheel-wrapper").forEach((n) => ((n as HTMLImageElement).style.animationDuration = wheelSpeed));

  const otherWheelSize = wheelSize === 19 ? 21 : 19;

  document.querySelectorAll(".car-wheel").forEach((n) => {
    n.classList.remove("size-19", "size-21");
    n.classList.add(`size-${otherWheelSize}`);
  });
}

function up(selector) {
  const element = document.querySelector(selector);

  // IE11 doesn't support stepUp(), so we do it manually
  const step = parseInt(element.step);
  const max = parseInt(element.max);
  const min = parseInt(element.min);
  const val = parseInt(element.value);
  const newVal = Math.min(Math.max(val + step, min), max);

  if (val !== newVal) {
    element.value = Math.min(Math.max(newVal, min), max);

    const event = document.createEvent("Event");
    event.initEvent("input", false, true);
    element.dispatchEvent(event);
  }
}

function down(selector) {
  const element = document.querySelector(selector);

  // IE11 doesn't support stepUp(), so we do it manually
  const step = parseInt(element.step);
  const max = parseInt(element.max);
  const min = parseInt(element.min);
  const val = parseInt(element.value);
  const newVal = Math.min(Math.max(val - step, min), max);

  if (val !== newVal) {
    element.value = Math.min(Math.max(newVal, min), max);

    const event = document.createEvent("Event");
    event.initEvent("input", false, true);
    element.dispatchEvent(event);
  }
}

window.onload = init;
